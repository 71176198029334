import { Component, Vue, Prop } from "vue-facing-decorator";
import { MenuItem } from "primevue/menuitem";
import Menu from "primevue/menu";

@Component({})
export default class ActionMenuControlComponent extends Vue {
    @Prop() public actionMenuItems!: Array<MenuItem>;

    @Prop() public buttonText!: string;

    @Prop({ default: false }) isMultiSelect?: boolean;

    created(): void {}

    declare $refs: {
        actionMenu: Menu;
    };

    async onClickActionMenuToggle(event: Event) {
        this.$refs.actionMenu.toggle(event);
    }

    reset(): void {
        this.actionMenuItems.forEach(a => a.isToggled = false);
    }
}
