import { Component, Vue, Prop } from "vue-facing-decorator";
import { TableModel } from "@/models/table/table.model";
import { TreeTableModel } from "@/models/table/tree.table.model";
import Tooltip from "primevue/tooltip";
import { FieldType } from "@/models/table/field.type";
import BaseControl from "@/components/complex.controls/base.control";

@Component({
    directives: {
        Tooltip
    }
})
export default class TreeTableComponent extends BaseControl {
    @Prop()
    table!: TableModel;

    @Prop()
    value!: TreeTableModel[];

    @Prop({ default: false })
    isLoading!: boolean;

    @Prop()
    loadingText!: string;

    @Prop({ default: true })
    showGlobalSearch!: boolean;

    @Prop({ default: false})
    isTechVesselPage!: boolean;

    fieldType = FieldType;
    expandedKeys: { [name: string]: boolean } = {};
    filters = { global: "" };

    showClipboardId: number | null = null;
    showClipboardIndex: number | null = null;
    clipboardVisibleTimer: number | undefined = undefined;
    isHoveringClipboard = false;

    get isMobileView(): boolean {
        return screen.width < 768;
    }

    expandAll() {
        for (const node of this.value) {
            this.expandNode(node);
        }

        this.expandedKeys = { ...this.expandedKeys };
    }

    collapseAll() {
        this.expandedKeys = {};
    }

    expandNode(node: TreeTableModel) {
        if (node.children && node.children.length) {
            this.expandedKeys[node.key] = true;

            for (const child of node.children) {
                this.expandNode(child);
            }
        }
    }

    selectRow(node: { [name: string]: boolean }) {
        const clickedNodeId = Object.getOwnPropertyNames(node)[0];
        if (Object.getOwnPropertyNames(this.expandedKeys).includes(clickedNodeId)) {
            delete this.expandedKeys[clickedNodeId];
        } else {
            this.expandedKeys = { ...this.expandedKeys, ...node };
        }
    }

    onFilter(e: { filters: { global: string }; originalEvent: { type: string } }) {
        // this event occurred even when you just expand/collapse sections
        if (e.originalEvent?.type == "input") {
            if (e.filters.global) {
                this.expandAll();
            } else {
                this.collapseAll();
            }
        }
    }

    showClipboardIcon(id: number, index: number): void {
        clearInterval(this.clipboardVisibleTimer);
        this.clipboardVisibleTimer = undefined;

        this.showClipboardId = id;
        this.showClipboardIndex = index;
    }

    hideClipboardIcon(id: number, index: number): void {
        this.clipboardVisibleTimer = setInterval(() => {
            this.showClipboardId = id;
            this.showClipboardIndex = index;
        }, 500);
    }

    async copyValueToClipboardAsync(event: Event, value: string): Promise<void> {
        event.stopImmediatePropagation();
        await navigator.clipboard.writeText(value);
    }

    clear() {
        this.filters.global = "";
        this.collapseAll();
    }

    hasSearchText(value: string | number): boolean {
        if (!value || !this.filters?.global) {
            return false;
        }
        return value.toString().toLowerCase().includes(this.filters?.global.toLowerCase());
    }

    select(): void {
        //TBD:: implement or delete checkbox when the requirements are clear
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle(event: Event, key: string, refs: any) {
        event.stopImmediatePropagation();

        refs[`menu${key}`]?.forEach((menu: any) => {
            menu.toggle(event);
        });
    }
}
