import { Component, Vue, Prop, Emit } from "vue-facing-decorator";
import { MenuItem } from "primevue/menuitem";
import Menu from "primevue/menu";
import { ComponentPublicInstance } from "vue";
import { fleetModule } from "@/store/fleet.module";
import { ResponseFleetVesselModel } from "@/models/response.models/response.fleet.vessel.model";
import { fleetSearchQueryKey, showDecommissionedVesselsKey } from "@/utilities/constants/local.storage";
import _ from "lodash";
import { vesselModule } from "@/store/vessel.module";
import { VesselFullModel } from "@/models/models/vessel.full.model";
import { VesselNameModel } from "@/models/models/vessel.name.model";
import router from "@/router";
import { ContactModel } from "@/models/models/contact.model";
import VesselHeaderComponent from "@/components/complex.controls/vessel.header/vessel.header.vue";
import { view } from "@/utilities/helpers/mobile.helper";

@Component({ components: { VesselHeaderComponent } })
export default class NavigationPageComponent extends Vue {
    @Prop() items!: MenuItem[];

    @Prop() component!: ComponentPublicInstance;

    @Prop() vesselInfo!: VesselFullModel;

    @Prop() aliases?: string;

    @Prop({ default: false }) isTechVessel?: boolean;

    @Prop({ default: false }) isAgent?: boolean;

    @Prop() agent?: ContactModel;

    @Prop() agentLogoContent?: string;

    searchQueryStorageKey: string = fleetSearchQueryKey;

    currentVessel!: ResponseFleetVesselModel;
    index!: number;
    isFirstInSeries: boolean = false;
    isLastInSeries: boolean = false;

    hideSubNavigationBar: boolean = false;
    showDecommissionedVessels: boolean = false;

    hideVesselHeader: boolean = true;
    lastScrollY: number = 0;

    declare $refs: {
        subMenu: Menu;
    };

    @Emit("onPrevious")
    previous(vesselId: number): number {
        return vesselId;
    }

    @Emit("onNext")
    next(vesselId: number): number {
        return vesselId;
    }

    @Emit("onVesselEdit")
    editVessel(): boolean {
        return this.isHighClassVessel;
    }

    @Emit("onAgentEdit")
    editAgent(): void {}

    @Emit("onShowHistory")
    showHistory(): void {}

    @Emit("onScroll")
    scroll(): boolean {
        return !this.hideVesselHeader;
    }

    get isTabletView(): boolean {
        return view.isTabletView();
    }

    get vessels(): ResponseFleetVesselModel[] {
        if (!fleetModule.vessels) return [];

        let collection = fleetModule.vessels.filter(v => {
            if (this.showDecommissionedVessels) return v;

            if (v.vesselStatus && v.vesselStatus !== "Decommissioned" && v.vesselStatus !== "No longer MJP jets") return v;
        });

        return collection;
    }

    get previousVesselNumber(): string | undefined {
        if (this.vessels) {
            if (this.index == 0) {
                this.isFirstInSeries = true;
                return "";
            }

            this.isLastInSeries = false;
            return this.vessels[this.index - 1]?.vesselNumber;
        }

        return "";
    }

    get nextVesselNumber(): string | undefined {
        if (this.vessels) {
            if (this.index == this.vessels.length - 1) {
                this.isLastInSeries = true;
                return "";
            }

            this.isFirstInSeries = false;
            return this.vessels[this.index + 1]?.vesselNumber;
        }

        return "";
    }

    get isHighClassVessel(): boolean {
        return this.vesselInfo.productType?.name.toLocaleLowerCase().includes("high class") ?? false;
    }

    onBeforeMount() {
        window.addEventListener("scroll", this.onScroll);
    }

    async created(): Promise<void> {
        const query = this.$route.query as any;
        if (query.goTo) await this.$nextTick(() => this.scrollTo(null, query.goTo));

        window.addEventListener("scroll", this.onScroll);

        if (this.isTechVessel) return;

        const showDecomVesselsKey = localStorage.getItem(showDecommissionedVesselsKey);
        if (showDecomVesselsKey) this.showDecommissionedVessels = Number(showDecomVesselsKey) === 1;

        if (!fleetModule.vessels || fleetModule.vessels.length < 1) {
            const queries = localStorage.getItem(this.searchQueryStorageKey);
            if (queries) await fleetModule.fetchFleet({queries: JSON.parse(queries!)});
        }

        if (vesselModule.currentVessel) {
            this.currentVessel = this.vessels.find(v => v.id === vesselModule.currentVessel.id)!;
            if (this.currentVessel)
                this.vessels.forEach((v, i) => {
                    if (v.id == this.currentVessel.id) this.index = i;
                });
        }

        if (this.vessels.length === 1) {
            this.isFirstInSeries = true;
            this.isLastInSeries = true;
        }

        if (this.index == 0) this.isFirstInSeries = true;
        if (this.index == this.vessels.length - 1) this.isLastInSeries = true;
    }

    async previousAsync(): Promise<void> {
        if (this.index == 0) return;

        if (this.index == 1) this.isFirstInSeries = true;

        --this.index;
        this.currentVessel = this.vessels[this.index];
        this.previous(this.currentVessel.id);
    }

    async nextAsync(): Promise<void> {
        if (this.index == this.vessels.length - 1) return;

        ++this.index;
        this.currentVessel = this.vessels[this.index];
        this.next(this.currentVessel.id);
    }

    scrollTo(e: Event | null, url: string | undefined) {
        if (url) {
            const element = this.component.$refs[url] as HTMLElement;

            if (url === "top") {
                window.scrollTo(0, 0);
            } else {
                if (element) {
                    const top = element.offsetTop - 200;
                    window.scrollTo(0, top);
                } else {
                    window.scrollTo(0, 0);
                }
            }
            if (e) {
                this.$refs.subMenu.toggle(e);
            }
        }
    }

    getVesselName(vesselNames: VesselNameModel[], version: number): string {
        if (!vesselNames?.length) return "-";

        const maxVersion = Math.max(...vesselNames?.map(vn => vn.version));
        return this.parseEmpty(vesselNames.find(vn => vn.version == maxVersion - version)?.name);
    }

    parseEmpty(value: string | number | null | undefined) {
        return value ? value.toString() : "-";
    }

    async backToVesselAsync(): Promise<void> {
        await router.push(`/vessel/${this.vesselInfo.id}`);
    }

    private onScroll(): void {
        const currentScrollY = window.scrollY;
        const scrollThreshold = 15; // Adjust this value as needed

        if (currentScrollY > this.lastScrollY + scrollThreshold) this.hideVesselHeader = false;
        
        if (currentScrollY === 0) this.hideVesselHeader = true;

        this.lastScrollY = currentScrollY;

        this.scroll();
    }
}
