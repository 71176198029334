import VesselPage from "@/components/pages/technician/vessel/vessel.page";
import VesselPageAdmin from "@/components/pages/admin/vessel/vessel.page";
import { attachmentManager } from "@/managers/attachment.manager";
import { operatingProfileManager } from "@/managers/operating.profile.manager";
import { softwareVersionManager } from "@/managers/software.version.manager";
import { toastManager } from "@/managers/toast.manager";
import { UserRoles } from "@/models/enums/user.roles";
import { ResponseAttachmentModel } from "@/models/response.models/response.attachment.model";
import ResponseOperatingProfileModel from "@/models/response.models/response.operating.profile.model";
import ResponseSoftwareVersionModel from "@/models/response.models/response.software.version.model";
import { FieldType } from "@/models/table/field.type";
import { TableModel } from "@/models/table/table.model";
import { authModule } from "@/store/auth.module";
import { operatingProfileModule } from "@/store/operating.profile.module";
import { softwareVersionModule } from "@/store/software.version.module";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { DataTableOperatorFilterMetaData } from "primevue/datatable";
import { isAdmin, isMjp, isSuperAdmin } from "./user.role.helper";
import { attachmentModule } from "@/store/attachment.module";
import { EventStatus } from "@/models/enums/event.status";
import { enumHelper } from "./enum.helper";
import { ResponseEventLogModel } from "@/models/response.models/response.eventLog.model";
import { eventLogManager } from "@/managers/event.log.manager";
import { RequestUpdateStatusEventLogModel } from "@/models/request.models/request.update.status.event.log.model";
import { eventLogModule } from "@/store/eventlog.module";
import { serviceModule } from "@/store/service.module";
import ServiceKitModel, { ServiceKitTreeDataModel } from "@/models/models/service.kit/service.kit.model";
import { serviceKitManager } from "@/managers/service.kit.manager";
import ServiceKitComponent from "@/components/complex.controls/service.kit/service.kit";
import SparePartOrderComponent from "@/components/complex.controls/spare.part.order/spare.part.order";
import SparePartOrderModel from "@/models/models/spare.part.order/spare.part.order.model";
import OfferComponent from "@/components/complex.controls/offer/offer";
import { offerManager } from "@/managers/offer.manager";
import OfferModel from "@/models/models/offer/offer.model";
import { ServiceStatus } from "@/models/enums/service.status";

export const vesselTables = {
    operatingProfile(component: VesselPage | VesselPageAdmin, _: boolean = false): TableModel {
        const defaultFilterConfiguration: DataTableOperatorFilterMetaData = {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
        };

        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                addedBy: defaultFilterConfiguration
            },
            columns: [
                {
                    fieldType: FieldType.Normal,
                    fieldName: "dateToStringLocalize",
                    header: component.$t("operatingProfile.date"),
                    mobileRow: true,
                    index: 0
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "runningHoursString",
                    header: component.$t("operatingProfile.runningHours"),
                    width: "flex",
                    mobileRow: true,
                    index: 1
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "avgAnnual",
                    header: component.$t("operatingProfile.avgAnnual")
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "annualDeviation",
                    header: component.$t("operatingProfile.annualDeviation"),
                    mobileRow: component.isTabletView,
                    index: 3
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "avgAnnual_3",
                    header: component.$t("operatingProfile.avgAnnual_3")
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "addedBy",
                    header: component.$t("operatingProfile.addedBy"),
                    sortField: "addedBy",
                    mobileRow: component.isTabletView,
                    index: 2
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: ResponseOperatingProfileModel) => {
                            return [
                                {
                                    label: component.$t("global.button.edit"),
                                    icon: require("../../../public/icons/edit.svg"),
                                    command: () => component.setSubComponent("OperatingProfileCreateComponent", { profile: model })
                                },
                                {
                                    label: component.$t("global.button.delete"),
                                    icon: require("../../../public/icons/remove.svg"),
                                    command: async () => {
                                        component.setSubComponent("ConfirmationDialogComponent", {
                                            header: component.$t("global.confirmations.header"),
                                            messages: [component.$t("global.confirmations.message")],
                                            confirmCallback: async () => {
                                                await operatingProfileManager.deleteOperationProfile(model.id);
                                                await operatingProfileModule.fetchOperatingProfiles(component.vesselId);
                                                if (component.isMobileView || component.isTabletView) component.refreshMobileTableValues(4, component.operatingProfiles);
                                            }
                                        });
                                    }
                                }
                            ];
                        }
                    }
                }
            ]
        };
    },

    softwareVersion(component: VesselPage | VesselPageAdmin): TableModel {
        const getAdditionalData: any = (isBuildNew: (model: ResponseSoftwareVersionModel) => boolean, getValue: (model: ResponseSoftwareVersionModel) => string): any => {
            return {
                getImageSrc: (model: ResponseSoftwareVersionModel): any => {
                    return isBuildNew(model) ? require("../../../public/icons/upgrade.svg") : "";
                },
                getTextRelatedToImage: (model: ResponseSoftwareVersionModel): string => getValue(model),
                invert: true,
                getStyle: (model: ResponseSoftwareVersionModel): Partial<CSSStyleDeclaration> => (isBuildNew(model) ? { width: "1rem" } : { width: "auto" })
            };
        };

        const defaultFilterConfiguration: DataTableOperatorFilterMetaData = {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
        };

        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                addedBy: defaultFilterConfiguration
            },
            columns: [
                {
                    fieldType: FieldType.Normal,
                    fieldName: "fromDate",
                    header: component.$t("softwareVersion.fromDate"),
                    width: "8rem",
                    mobileRow: true,
                    index: 0
                },
                {
                    fieldName: "portHardware.controlSystemType.name",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.controlSystemType"),
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions.find(sw => sw.id === model.id)?.isControlSystemTypeNew,
                        (model: ResponseSoftwareVersionModel) => model.controlSystemType
                    )
                },
                {
                    fieldName: "portHardware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.hw"),
                    width: "5rem",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isPortHardwareNew,
                        (model: ResponseSoftwareVersionModel) => model.portHardware.build
                    )
                },
                {
                    fieldName: "portSoftware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.portSwBuild"),
                    width: "flex",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isPortSoftwareNew,
                        (model: ResponseSoftwareVersionModel) => model.portSoftware.build
                    )
                },
                {
                    fieldName: "starboardHardware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.hw"),
                    width: "5rem",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isStarboardHardwareNew,
                        (model: ResponseSoftwareVersionModel) => model.starboardHardware.build
                    )
                },
                {
                    fieldName: "starboardSoftware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.starboardSwBuild"),
                    width: "flex",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isStarboardSoftwareNew,
                        (model: ResponseSoftwareVersionModel) => model.starboardSoftware.build
                    )
                },
                {
                    fieldName: "displayHardware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.hw"),
                    width: "5rem",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isDisplayHardwareNew,
                        (model: ResponseSoftwareVersionModel) => model.displayHardware.build
                    )
                },
                {
                    fieldName: "displaySoftware.build",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("softwareVersion.displaySwBuild"),
                    width: "flex",
                    additionalData: getAdditionalData(
                        (model: ResponseSoftwareVersionModel) => component.softwareVersions?.find(sw => sw.id === model.id)?.isDisplaySoftwareNew,
                        (model: ResponseSoftwareVersionModel) => model.displaySoftware.build
                    )
                },
                {
                    fieldName: "comment",
                    fieldType: FieldType.LongText,
                    header: component.$t("softwareVersion.comment"),
                    width: "flex",
                    mobileRow: component.isTabletView,
                    index: 2
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "addedBy",
                    header: component.$t("softwareVersion.addedBy"),
                    sortField: "addedBy",
                    mobileRow: true,
                    index: 1
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: ResponseSoftwareVersionModel) => {
                            return [
                                {
                                    label: component.$t("global.button.edit"),
                                    icon: require("../../../public/icons/edit.svg"),
                                    command: async () => {
                                        component.setSubComponent("SoftwareVersionCreateComponent", {
                                            softwareVersion: model,
                                            vesselId: component.vesselId,
                                            onCrudAsync: async () => {
                                                await softwareVersionModule.fetchSoftwareVersions(component.vesselId);
                                                if (component.isMobileView || component.isTabletView) component.refreshMobileTableValues(3, component.softwareVersions);
                                            }
                                        });
                                    }
                                },
                                {
                                    label: component.$t("global.button.delete"),
                                    icon: require("../../../public/icons/remove.svg"),
                                    command: async () => {
                                        component.setSubComponent("ConfirmationDialogComponent", {
                                            header: component.$t("global.confirmations.header"),
                                            messages: [component.$t("global.confirmations.message")],
                                            confirmCallback: async () => {
                                                await softwareVersionManager.deleteSoftwareVersion(model.id);
                                                await softwareVersionModule.fetchSoftwareVersions(component.vesselId);
                                                if (component.isMobileView || component.isTabletView) component.refreshMobileTableValues(3, component.softwareVersions);
                                            }
                                        });
                                    }
                                }
                            ];
                        }
                    }
                }
            ]
        };
    },

    attachment(component: VesselPage | VesselPageAdmin, isTechnician: boolean = false): TableModel {
        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            },
            columns: [
                {
                    fieldType: FieldType.Image,
                    sortField: "imageSource",
                    fieldName: "contentType",
                    getData: (model: ResponseAttachmentModel) => model.imageSource,
                    groupField: true,
                    header: component.$t("vessel.attachment.type")
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "fileName",
                    sortField: "fileName",
                    header: component.$t("vessel.attachment.fileName"),
                    width: "flex",
                    mobileRow: true,
                    index: 0
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "lastUpdateLocalized",
                    sortField: "lastUpdateLocalized",
                    header: component.$t("vessel.attachment.lastUpdate"),
                    mobileRow: component.isTabletView,
                    index: 2
                },
                {
                    fieldType: FieldType.AvatarField,
                    fieldName: "addedBy",
                    header: component.$t("vessel.attachment.added"),
                    sortField: "addedBy",
                    groupField: true,
                    mobileRow: true,
                    index: 1
                },
                {
                    fieldType: FieldType.CheckboxAction,
                    fieldName: "isPublic",
                    header:
                        authModule.currentUser?.role === UserRoles.SuperAdmin || authModule.currentUser?.role === UserRoles.MjpAdmin
                            ? component.$t("vessel.attachment.public")
                            : "",
                    condition: (_: ResponseAttachmentModel) => authModule.currentUser?.role === UserRoles.SuperAdmin || authModule.currentUser?.role === UserRoles.MjpAdmin,
                    groupField: true,
                    width: authModule.currentUser?.role === UserRoles.SuperAdmin || authModule.currentUser?.role === UserRoles.MjpAdmin ? "12rem" : "1rem",
                    additionalData: {
                        callback: async (model: ResponseAttachmentModel) => {
                            const res = await attachmentManager.updateIsPublicAsync(model.id, model.isPublic);
                            if (res instanceof Error) toastManager.error(component, component.$t("toast.error"), component.$t("toast.genericErrorMessage"));
                        },
                        isReadOnly: isTechnician
                    }
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: ResponseAttachmentModel) => {
                            let menus = [
                                {
                                    label: component.$t("vessel.attachment.download"),
                                    icon: require("../../../public/icons/icon_download.svg"),
                                    command: async () => await attachmentManager.downloadAttachmentAsync(model.id, model.fileName)
                                },
                                {
                                    label: component.$t("vessel.attachment.preview"),
                                    icon: require("../../../public/icons/eye.svg"),
                                    command: async () => await attachmentManager.viewAttachmentAsync(model.id)
                                }
                            ];

                            if (isSuperAdmin(authModule.currentUser?.role) || isMjp(authModule.currentUser?.role) || authModule.currentUser?.id == model.createdByUser.id) {
                                menus = [
                                    ...menus,
                                    {
                                        label: component.$t("global.button.delete"),
                                        icon: require("../../../public/icons/remove.svg"),
                                        command: async () => {
                                            component.setSubComponent("ConfirmationDialogComponent", {
                                                header: component.$t("global.confirmations.header"),
                                                messages: [component.$t("global.confirmations.message")],
                                                confirmCallback: async () => {
                                                    await attachmentManager.deleteAttachment(model.id);
                                                    await attachmentModule.fetchAttachments(component.vesselId);
                                                    if (component.isMobileView || component.isTabletView) component.refreshMobileTableValues(5, component.attachments);
                                                }
                                            });
                                        }
                                    }
                                ];
                            }

                            return menus;
                        }
                    }
                }
            ]
        };
    },

    event(component: VesselPageAdmin): TableModel {
        return {
            columns: [
                {
                    fieldType: FieldType.Normal,
                    fieldName: "creationDateLocalized",
                    header: component.$t("eventLog.table.columns.date"),
                    sortField: "creationDate",
                    width: "250px",
                    mobileRow: true
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "type.name",
                    header: component.$t("eventLog.table.columns.type"),
                    sortField: "type.name",
                    groupField: true
                },
                {
                    fieldType: FieldType.LinkText,
                    fieldName: "title",
                    header: component.$t("eventLog.table.columns.title"),
                    sortField: "title",
                    width: "flex",
                    mobileRow: true,
                    additionalData: {
                        callback: async (event: ResponseEventLogModel) => {
                            const eventLog = await eventLogManager.getEventLog(event.id);
                            component.setSubComponent("EventLogCreateComponent", { eventLogModel: eventLog });
                        }
                    }
                },
                {
                    fieldType: FieldType.LongText,
                    fieldName: "description",
                    header: component.$t("eventLog.table.columns.description"),
                    sortField: "description"
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "handlerText",
                    header: component.$t("eventLog.table.columns.assignedTo"),
                    sortField: "handlerText"
                },
                {
                    fieldType: FieldType.AvatarField,
                    fieldName: "createdByUserFullName",
                    header: component.$t("eventLog.table.columns.added"),
                    sortField: "createdByUserFullName",
                    groupField: true
                },
                {
                    fieldName: "eventStatus",
                    fieldType: FieldType.ColoredDropdown,
                    header: component.$t("eventLog.table.columns.status"),
                    sortField: "eventStatus",
                    groupField: true,
                    additionalData: {
                        callback: async (value: EventStatus, eventLogData: ResponseEventLogModel) => {
                            await eventLogManager.updateEventLogStatus({
                                eventStatus: value,
                                id: eventLogData.id
                            } as RequestUpdateStatusEventLogModel);

                            const exist = eventLogModule.eventLogs.find(e => e.id == eventLogData.id);
                            if (exist) exist.eventStatus = value;

                            toastManager.success(component, component.$t("toast.eventLogUpdate"), component.$t("toast.updateSuccess"));
                        }
                    }
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: ResponseEventLogModel) => {
                            return [
                                {
                                    label: component.$t("eventLog.table.columns.showEdit"),
                                    icon: require("../../../public/icons/edit.svg"),
                                    command: async () => {
                                        const eventLog = await eventLogManager.getEventLog(model.id);
                                        component.setSubComponent("EventLogCreateComponent", {
                                            eventLogModel: eventLog
                                        });
                                    }
                                },
                                {
                                    label: component.$t("global.button.delete"),
                                    icon: require("../../../public/icons/remove.svg"),
                                    command: async () => {
                                        component.setSubComponent("ConfirmationDialogComponent", {
                                            header: component.$t("global.confirmations.header"),
                                            messages: [component.$t("global.confirmations.message")],
                                            confirmCallback: async () => {
                                                await eventLogManager.deleteEventLog(model.id);

                                                await eventLogModule.fetchEventLogs(component.vesselId);
                                                await serviceModule.fetchSlimServicesAsync(component.vesselId);

                                                if (component.isMobileView || component.isTabletView) component.refreshMobileTableValues(0, component.eventLogsData);
                                            }
                                        });
                                    }
                                }
                            ];
                        }
                    }
                }
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            },
            coloredDropdownColors: [
                { value: EventStatus.Open, imageSource: require("../../../public/status-orange.svg") },
                { value: EventStatus.InProgress, imageSource: require("../../../public/status-green.svg") },
                { value: EventStatus.ServicePerformed, imageSource: require("../../../public/status-blue.svg") },
                { value: EventStatus.Resolved, imageSource: require("../../../public/status-gray.svg") }
            ],
            coloredDropdownOptions: enumHelper.getValueFromEnum(EventStatus, component)
        };
    },

    kit(component: VesselPageAdmin | ServiceKitComponent): TableModel {
        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            },
            columns: [
                {
                    fieldName: "item",
                    fieldType: FieldType.LinkText,
                    header: component.$t("sparePartOrder.partNumber"),
                    width: "20rem",
                    mobileRow: true,
                    expander: true,
                    additionalData: {
                        callback: async (model: ServiceKitTreeDataModel): Promise<void> => {
                            if (model.drawingId) {
                                const res = await serviceKitManager.getDrawingDataAsync(model.drawingId);
                                if (res instanceof Error) {
                                    toastManager.error(component, component.$t("toast.error"), component.$t("toast.genericErrorMessage"));
                                    return;
                                }
                                component.setSubComponent("DrawingComponent", { drawingData: res, kit: model });
                            } else {
                                component.setSubComponent("DrawingComponent", { kit: model });
                            }
                        },
                        hasLink: (model: ServiceKitTreeDataModel) => model.drawingId !== null
                    }
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "description",
                    header: component.$t("sparePartOrder.desc"),
                    width: "flex",
                    mobileRow: true
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "quantity",
                    header: component.$t("part.table.columns.quantity"),
                    width: "flex"
                },
                {
                    fieldType: FieldType.IconTooltip,
                    fieldName: "",
                    header: "",
                    width: "flex",
                    additionalData: {
                        onHover: (kit: ServiceKitTreeDataModel) => {
                            let text: string = "";

                            if (kit.mappedIncorrectly && !kit.isTopLevel)
                                text += `This kit is missing in the parts list.${kit.amountOfChildrenMappedInCorrectly > 0 ? "\n\n" : ""}`;
                            if (kit.amountOfChildrenMappedInCorrectly > 0)
                                text += `${kit.amountOfChildrenMappedInCorrectly} ${kit.amountOfChildrenMappedInCorrectly === 1 ? "sub part is" : "sub parts are"} missing.`;

                            return text;
                        },
                        predicate: (kit: ServiceKitTreeDataModel) =>
                            (isMjp(authModule.currentUser?.role) || isAdmin(authModule.currentUser?.role)) &&
                            ((!kit.isTopLevel && kit.mappedIncorrectly) || kit.amountOfChildrenMappedInCorrectly > 0)
                    }
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: ServiceKitModel) => {
                            return [
                                {
                                    label: component.$t("global.button.delete"),
                                    icon: require("../../../public/icons/remove.svg"),
                                    command: async () => {
                                        const res = await serviceKitManager.removeServiceKitInstanceAsync(model.id, model.vesselId!);

                                        if (res instanceof Error) {
                                            toastManager.error(component, component.$t("toast.error"), component.$t("toast.genericErrorMessage"));
                                            return;
                                        }

                                        if (typeof (component as any).loadServiceKitDataAsync === "function") {
                                            const c = component as any;
                                            await c.loadServiceKitDataAsync();
                                            if (c.isMobileView) c.refreshMobileTableValues(1, component.serviceKits ?? []);
                                            return;
                                        }

                                        if (typeof (component as any).load === "function") (component as any).load();
                                    }
                                }
                            ];
                        }
                    }
                }
            ]
        };
    },

    order(component: VesselPageAdmin | SparePartOrderComponent): TableModel {
        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            },
            columns: [
                {
                    fieldName: "orderNumber",
                    fieldType: FieldType.LinkText,
                    header: component.$t("sparePartOrder.orderNumber"),
                    width: "8rem",
                    mobileRow: true,
                    additionalData: {
                        callback: async (model: SparePartOrderModel): Promise<void> => {
                            const res = await serviceKitManager.getSparePartOrderAsync(model.id);
                            if (res instanceof Error) return;
                            component.setSubComponent("SparePartOrderRowsComponent", { order: res, orders: component.sparePartOrders });
                        }
                    }
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "goodsLabel",
                    header: component.$t("sparePartOrder.goodsLabel"),
                    width: "flex"
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "orderedDateString",
                    header: component.$t("sparePartOrder.orderDate"),
                    width: "8rem",
                    mobileRow: true
                },
                {
                    fieldName: "status",
                    fieldType: FieldType.ImageWithText,
                    header: component.$t("sparePartOrder.status"),
                    width: "25rem",
                    additionalData: {
                        getImageSrc: (model: SparePartOrderModel): any => {
                            switch (model.statusCode) {
                                case 0:
                                    return require("../../../public/status-black.svg");
                                case 1:
                                    return require("../../../public/status-blue.svg");
                                case 2:
                                    return require("../../../public/status-blue.svg");
                                case 3:
                                    return require("../../../public/status-orange.svg");
                                case 4:
                                    return require("../../../public/status-green.svg");
                                case 5:
                                    return require("../../../public/status-green.svg");
                                case 9:
                                    return require("../../../public/status-black.svg");
                            }
                        },
                        getTextRelatedToImage: (model: SparePartOrderModel): string => model.statusDescription,
                        getStyle: (_: SparePartOrderModel): Partial<CSSStyleDeclaration> => ({ marginRight: "8px" })
                    }
                }
            ]
        };
    },

    offer(component: VesselPageAdmin | OfferComponent): TableModel {
        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            },
            columns: [
                {
                    fieldType: FieldType.Normal,
                    fieldName: "partNumber",
                    header: component.$t("admin.offering.partNumber"),
                    sortField: "part.partNumber",
                    width: "flex",
                    mobileRow: true,
                    index: 0
                },
                {
                    fieldType: FieldType.Normal,
                    fieldName: "description",
                    header: component.$t("admin.offering.desc"),
                    sortField: "part.description",
                    width: "flex",
                    mobileRow: true,
                    index: 1
                },
                {
                    fieldType: FieldType.LongText,
                    fieldName: "additionalInformation",
                    header: component.$t("admin.offering.additionalInformation"),
                    sortField: "additionalInformation",
                    width: "flex",
                    mobileRow: component.isTabletView,
                    index: 2,
                    additionalData: {
                        tabletHeaderLabelStyle: (): Partial<CSSStyleDeclaration> => ({ width: "10rem" })
                    }
                },
                {
                    fieldName: "id",
                    fieldType: FieldType.Menu,
                    width: "60px",
                    additionalData: {
                        getItems: (model: OfferModel) => {
                            return [
                                {
                                    label: component.$t("global.button.delete"),
                                    icon: require("../../../public/icons/remove.svg"),
                                    command: async () => {
                                        const res = await offerManager.removeOfferAsync(model.id);

                                        if (res instanceof Error) {
                                            toastManager.error(component, component.$t("toast.error"), component.$t("toast.genericErrorMessage"));
                                            return;
                                        }

                                        if (typeof (component as any).loadOffersDataAsync === "function") {
                                            const c = component as any;
                                            c.loadOffersDataAsync(c.vesselId);
                                            if (c.isMobileView) c.refreshMobileTableValues(6, c.offers ?? []);
                                            return;
                                        }

                                        if (typeof (component as any).load === "function") (component as any).load();
                                    }
                                }
                            ];
                        }
                    }
                }
            ]
        };
    }
};

export const service = {
    svg(status: ServiceStatus): string {
        switch (status) {
            case ServiceStatus.Tentative:
                return require("../../../public/status-orange.svg");
            case ServiceStatus.Planned:
                return require("../../../public/status-green.svg");
            case ServiceStatus.Confirmed:
                return require("../../../public/status-green.svg");
            case ServiceStatus.ServicePerformed:
                return require("../../../public/status-blue.svg");
            case ServiceStatus.Completed:
                return require("../../../public/status-gray.svg");
            default:
                return "";
        }
    }
};

export const swiper = {
    onTouchStart(event: TouchEvent): number {
        return event.touches[0].clientX;
    },

    onTouchmove(event: TouchEvent, touchStart: number, onRight: () => void, onLeft: () => void): number {
        const deltaX = event.touches[0].clientX - touchStart;

        if (Math.abs(deltaX) >= 200) {
            if (deltaX > 0) onRight();
            else onLeft();

            return event.touches[0].clientX;
        }

        return touchStart;
    }
};

export const view = {
    isTabletView(): boolean {
        return screen.width <= 1024 && screen.width >= 768;
    }
};
