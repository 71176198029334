import httpClient from "../utilities/helpers/http.client.axios";
import { AxiosInstance, AxiosResponse } from "axios";
import { plainToInstance } from "class-transformer";
import { ResponsePartFullModel } from "@/models/response.models/response.part.full.model";
import { ResponsePartsTreeModel } from "@/models/response.models/response.parts.tree.model";
import { ResponseModifiedPartModel } from "@/models/response.models/response.modified.part.model";
import { ResponseSyncHistoryModel } from "@/models/response.models/response.sync.history.model";
import { ResponsePartModel } from "@/models/response.models/response.part.model";
import ProductionOrderLogModel from "@/models/models/production.order.log.model";
import ProjectOrderRequestModel from "@/models/request.models/project.order.request.model";
import CustomerOrderModel from "@/models/models/customer.order/customer.order.model";
import CustomerOrderRowModel from "@/models/models/customer.order/customer.order.row.model";
import ManufacturingOrderModel from "@/models/models/manufacturing.order/manufacturing.order.model";
import ManufacturingOrderRowsRequestModel from "@/models/request.models/manufacturing.order.rows.request.model";
import ManufacturingOrderRowModel from "@/models/models/manufacturing.order/manufacturing.order.row.model";
import ProductionOrderLogRequest from "@/models/models/manufacturing.order/production.order.log.request";

export class PartManager {
    private readonly api: AxiosInstance = httpClient;

    public async getPartList(): Promise<ResponsePartModel[] | Error> {
        try {
            const response: AxiosResponse<ResponsePartModel[]> = await this.api.get("/Part/GetPartList");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getModifiedPartsByServiceIdQrCodeAsync(code: string): Promise<ResponseModifiedPartModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseModifiedPartModel[]> = await this.api.get(`/Part/GetModifiedPartsByServiceIdQrCode/${code}`);
            return plainToInstance(ResponseModifiedPartModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async synchronizeParts(): Promise<void | Error> {
        try {
            await this.api.post("/Part/SynchronizeParts");
        } catch (error) {
            return error as Error;
        }
    }

    public async getSyncHistory(): Promise<ResponseSyncHistoryModel | Error> {
        try {
            const response: AxiosResponse<ResponseSyncHistoryModel> = await this.api.get("/Part/GetSyncHistory");
            return plainToInstance(ResponseSyncHistoryModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getProductionOrderLogsAsync(vesselId: number): Promise<ProductionOrderLogModel[] | Error> {
        try {
            const response: AxiosResponse<ProductionOrderLogModel[]> = await this.api.get(`/Part/ProductionOrderLogs/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createProductionOrderLogsAsync(request: ProductionOrderLogModel, parentId?: string): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/Part/Create/${parentId}`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateProductionOrderLogsAsync(request: ProductionOrderLogModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/Part/Update`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteProductionOrderLogsAsync(id: string): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.delete(`/Part/Delete/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getCustomerOrdersAsync(request: ProjectOrderRequestModel): Promise<CustomerOrderModel[] | Error> {
        try {
            const response: AxiosResponse<CustomerOrderModel[]> = await this.api.post(`/Part/CustomerOrders/`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getCustomerOrderRowsAsync(parentOrderId: string): Promise<CustomerOrderRowModel[] | Error> {
        try {
            const response: AxiosResponse<CustomerOrderRowModel[]> = await this.api.get(`/Part/CustomerOrderRows/${parentOrderId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getManufacturingOrdersAsync(request: ProjectOrderRequestModel): Promise<ManufacturingOrderModel[] | Error> {
        try {
            const response: AxiosResponse<ManufacturingOrderModel[]> = await this.api.post(`/Part/ManufacturingOrders/`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getManufacturingOrderRowsAsync(request: ManufacturingOrderRowsRequestModel): Promise<ManufacturingOrderRowModel[] | Error> {
        try {
            const response: AxiosResponse<ManufacturingOrderRowModel[]> = await this.api.post(`/Part/ManufacturingOrderRows/`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async importPartsAsync(request: ProductionOrderLogRequest[], vesselId: number): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/Part/ImportParts/${vesselId}`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async doesVesselHavePartsAsync(vesselId: number): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get(`/Part/DoesVesselHaveParts/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async syncPartsPreparationAsync(request: string[]): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/Part/SyncPartsPreparation/`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async syncPartPreparationAsync(vesselId: number, logId: string, partNumber: string): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/Part/SynchronizePartPreparation/${vesselId}/${logId}/${partNumber}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async importPartsListForVesselsAsync(request: string[]): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/Part/ImportPartsListForVessels/`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    private processDate(tree: ResponsePartsTreeModel[]): void {
        tree.forEach(part => {
            part.data = plainToInstance(ResponsePartFullModel, part.data);
            if (part.children) {
                this.processDate(part.children);
            }
        });
    }
}
export const partManager = new PartManager();
